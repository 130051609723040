import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/essence.scss'
import AgaveRoot from "../images/inline/agave_root.inline.svg";
import Volumen from "../images/inline/volumen.inline.svg";
import Info from "../images/inline/info.inline.svg";
import Arrow from "../images/inline/arrow.inline.svg";
import Coccion from "../images/inline/coccion.inline.svg";
import Materia from "../images/inline/materia.inline.svg";
import Alcohol from "../images/inline/alcohol.inline.svg";
import * as SingleStyles from '../styles/tequila-single.module.scss'
import {useState} from "react";
import Close from "../images/inline/x.inline.svg";
import Sello from "../images/inline/sello.inline.svg";
import Est from "../images/inline/est.inline.svg";
import Color from "../images/inline/color.inline.svg";
import Notes from "../images/inline/notes.inline.svg";
import {graphql} from "gatsby";
import {Trans} from "gatsby-plugin-react-i18next";
import {Parallax} from "react-scroll-parallax";
import * as styles from "../styles/tequila-single.module.scss";

const EssencePage = () => {

    const [modalActive, setModalActive] = useState('none');

    function showModal(modal: string): void {
        setModalActive(modal)
    }

    return (
        <Layout>
            <title>Santaleza</title>

            <section className="main_section">

                <div className="main_logo">
                    <StaticImage class="image" src="../images/ouressence/pina_tequila_ilustracion.svg" alt="pina"/>
                    <h2 className="to">
                        <Parallax speed={50} translateY={[-15, 15]}>
                            <Trans>Our essence</Trans>
                        </Parallax>
                    </h2>
                </div>

                <div className="main_image">
                    <Parallax className={'image'} speed={50} translateY={[15, -15]}>
                        {/* <StaticImage src="../images/ouressence/main_our_essence_img.jpg" alt="personas_trabajando"/> */}
                        <div className="embed-responsive embed-responsive-16by9" style={{ marginBottom: '40px' }}>
                            <iframe 
                            className="embed-responsive-item"
                            width="1280" 
                            height="720" 
                            src="https://www.youtube.com/embed/RnTCy0EfIxU" 
                            title="Santaleza Tequila brand video FINAL" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowFullScreen></iframe>

                        </div>
                    </Parallax>
                    <StaticImage className="stamp" src="../images/ouressence/sello_santaleza.svg"
                                 alt="sello santaleza"/>
                    <div className="main_scroll" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 650, behavior: `smooth`}) : ''
                    }}>
                        <p className="spettw">SCROLL DOWN</p>
                        <StaticImage src="../images/ouressence/scroll_down.svg" alt="flecha abajo"/>
                    </div>

                </div>

                <StaticImage className="map" src="../images/ouressence/mapa.png" alt="mapa mexico"/>

            </section>

            <section className="main_description">
                <StaticImage className="postcard" src="../images/ouressence/postal.png" alt="postal"/>
                <Parallax speed={50} translateY={[25, -25]} className="text_block">
                    <p className="subtt margen">Los Altos, Jalisco</p>
                    <div className="subtitle">
                        <StaticImage className="img-adorno" src="../images/ouressence/vineta.png" alt="viñeta lateral"/>
                        <h2 className="speto"><Trans>Land working</Trans></h2>
                    </div>
                    {<p className="parrafo">
                        <Trans>Strong sanctuary</Trans>
                    </p>}
                </Parallax>
            </section>

            <section id="second_section">

                {/* <Parallax speed={50} translateY={[25, -25]} id="second_image">
                    <StaticImage src="../images/ouressence/texto-back-vintage.png" alt="texto vintage"/>
                </Parallax> */}

                <div id="general_conteiner">
                    <Parallax speed={50} translateY={[-10, 25]} id="conteiner_first_image">
                        <StaticImage id="first_image" src="../images/ouressence/our_essences_worker.jpg" alt="jimador"/>
                    </Parallax>

                    <Parallax speed={50} translateY={[25, -25]} id="conteiner_information">
                        <div id="title_information">
                            <StaticImage src="../images/ouressence/vineta.png" alt="decoracion"/>
                            <h2 className="speto"><Trans>Our history</Trans></h2>
                        </div>

                        <p className="parrafo">
                            <Trans>History</Trans>
                        </p>
                    </Parallax>
                </div>
            </section>

            {/*<section className="third_section">
                <section className={SingleStyles.process}>
                    <AgaveRoot/>
                    <h2><Trans>Tequila Process</Trans></h2>
                    <div className={SingleStyles.items}>
                        <div className={SingleStyles.item}>
                            <span className={SingleStyles.name}>
                               <Volumen/>
                               01
                               <b><Trans>Harvest</Trans></b>
                               <button onClick={() => showModal('cosecha')}>
                                        <Info/>
                                </button>
                            </span>
                            <span className={SingleStyles.arrow}>
                             <Arrow/>
                            </span>
                            <span className={SingleStyles.name}>
                                 <Coccion/>
                                 02
                                 <b><Trans>Cooking</Trans></b>
                                 <button onClick={() => showModal('coccion')}>
                                     <Info/>
                                 </button>
                              </span>
                        </div>
                        <div className={SingleStyles.item}>
                            <span className={SingleStyles.name}>
                                <Volumen/>
                                03
                                <b><Trans>Milling</Trans></b>
                                <button onClick={() => showModal('molienda')}>
                                    <Info/>
                                </button>
                            </span>
                            <span className={SingleStyles.arrow}>
                            <Arrow/>
                        </span>
                        </div>
                        <div className={SingleStyles.item}>
                            <span className={SingleStyles.name}>
                                <Materia/>
                                04
                                <b><Trans>Fermentation</Trans></b>
                                <button onClick={() => showModal('fermentacion')}>
                                    <Info/>
                                </button>
                            </span>
                            <span className={SingleStyles.arrow}>
                                <Arrow/>
                            </span>
                            <span className={SingleStyles.name}>
                                <Alcohol/>
                                05
                                <b><Trans>Destilation</Trans></b>
                                <button onClick={() => showModal('destilacion')}>
                                    <Info/>
                                </button>
                            </span>
                        </div>
                    </div>
                    <StaticImage className={SingleStyles.image} src={'../images/ouressence/process.jpg'}
                                 alt={'Tequila'}/>
                </section>
            </section>*/}


            <section className={styles.notes} id={'different'}>
                    <div className={styles.right}>
                        <Notes/>
                        <div className={styles.content}>
                            <h2><Trans>Different</Trans></h2>
                            <ul className="bullet_list parrafo">
                                <li><Trans>What make us different_1st</Trans></li>
                                <li><Trans>What make us different_2nd</Trans></li>
                                <li><Trans>What make us different_3rd</Trans></li>
                                <li><Trans>What make us different_4th</Trans></li>
                                <li><Trans>What make us different_5th</Trans></li>
                                <li><Trans>What make us different_6th</Trans></li>
                            </ul>
                            {/* <p>
                                <Trans>What make us different</Trans>
                            </p> */}
                        </div>
                        <StaticImage className={styles.image} src={'../images/our-tequila/notes-2.jpg'} alt={'Tequila'}/>
                    </div>
            </section>


            <section id="four_section" className={'quote-section'}>

                <Parallax speed={50} translateY={[-25, 25]} className="bloque2-prueba our-essence">
                    <StaticImage className="agavito" src="../images/home/agavito_1.png" alt="agavito"/>
                    <p className="pbloq2 subto"><Trans>Santaleza slogan</Trans></p>
                    <StaticImage className="agavito" src="../images/home/agavito_2.png" alt="agavito"/>
                </Parallax>

                {/* <StaticImage src="../images/ouressence/vintage-text.png" className={'letters'} alt="texto vintage"/> */}

            </section>

            <section className="manifiesto_section" id={"our-essence"}>
                <div className={SingleStyles.notes}>
                    <Parallax speed={50} translateY={[25, -25]} className={SingleStyles.left}>
                        <div className={SingleStyles.content}>
                            <h3><Trans>Our essence</Trans></h3>
                            <h2>
                                <Color/>
                                <Trans>Our DNA</Trans></h2>
                            {/*<p>
                                We believe that reputation is more valuable than money.
                                We believe that someone’s word is the most important thing in a person.
                                We believe in shared values, in family, in happiness.
                                We believe in transforming ourselves and ascending, as long as our feet stay on the
                                ground. We believe that essence defines a human being.
                                We believe that we can touch the heart of the world. Tequila did it.
                            </p>*/}
                        </div>
                    </Parallax>
                    <Parallax speed={50} translateY={[35, -15]} className={SingleStyles.right}>

                        <Notes/>
                        <div className={SingleStyles.content}>
                            <p>It is true, there is a place called Tequila.
                               It is true that it gave its name to a drink that crossed borders. It is true that there 
                               are many families who continue doing it, breathing it, sweating it and carrying it in 
                               their veins. It is true that we made the tequila that has done it all.</p>
                            <p>“The tagline, ‘we made the tequila that has done it all,’ speaks to the history of our
                                family and the attention taken to make the best tequila possible – from soil to sip – to create an exceptional tequila that appeals to everyone”.</p>
                            <p className="quote-lineup">Juan Pablo Lopez-Villareal, owner of the Bonanza distillery where Santaleza Tequila is made</p>
                        </div>
                        <StaticImage className={SingleStyles.image} src={'../images/ouressence/manifiesto_image.jpg'}
                                     alt={'Tequila'}/>
                    </Parallax>
                </div>
            </section>


            <section className="six_section">
                <div className="barricas"><StaticImage src="../images/ouressence/footer_image_ouressence.jpg" alt="barricas"/></div>

                <div className="back_top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage src="../images/ouressence/up-arrow.png" alt="up"/>
                    <p>BACK<br/>TO TOP</p>
                </div>
            </section>

            {modalActive != 'none' &&
                <div className={SingleStyles.popUp}>
                    <div className={SingleStyles.bg}>
                        <div className={SingleStyles.content}>
                            <button className={SingleStyles.close} onClick={() => setModalActive('none')}>
                                <Close/>
                            </button>
                            <div className={SingleStyles.top}>
                                {modalActive === 'jima' &&
                                    <StaticImage className={SingleStyles.image} src={'../images/our-tequila/jima.jpg'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'coccion' &&
                                    <StaticImage className={SingleStyles.image}
                                                 src={'../images/our-tequila/coccion.jpg'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'molienda' &&
                                    <StaticImage className={SingleStyles.image}
                                                 src={'../images/our-tequila/molienda.jpg'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'fermentacion' &&
                                    <StaticImage className={SingleStyles.image}
                                                 src={'../images/our-tequila/fermentacion.jpg'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'destilacion' &&
                                    <StaticImage className={SingleStyles.image}
                                                 src={'../images/our-tequila/destilacion.jpg'}
                                                 alt={'tequila'}/>
                                }
                                <Sello/>
                            </div>
                            <div className={SingleStyles.bottom}>
                                {modalActive === 'cosecha' &&
                                    <>
                                        <h4>
                                            <Volumen/>
                                            <Trans>Harvest</Trans>
                                        </h4>
                                        <p><Trans>Harvest information</Trans></p>
                                    </>
                                }
                                {modalActive === 'coccion' &&
                                    <>
                                        <h4>
                                            <Coccion/>
                                            <Trans>Cooking</Trans>
                                        </h4>
                                        <p><Trans>Cooking information</Trans></p>

                                    </>
                                }
                                {modalActive === 'molienda' &&
                                    <>
                                        <h4>
                                            <Volumen/>
                                            <Trans>Milling</Trans>
                                        </h4>
                                        <p><Trans>Molienda information</Trans></p>
                                    </>
                                }
                                {modalActive === 'fermentacion' &&
                                    <>
                                        <h4>
                                            <Materia/>
                                            <Trans>Fermentation</Trans>
                                        </h4>
                                        <p><Trans>Fermentation information</Trans></p>
                                    </>
                                }
                                {modalActive === 'destilacion' &&
                                    <>
                                        <h4>
                                            <Alcohol/>
                                            <Trans>Destilation</Trans>
                                        </h4>
                                        <Trans>Destilation information</Trans>
                                    </>
                                }
                                <Est/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default EssencePage

export const pageQuery = graphql`
     query EssenceQuery ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`